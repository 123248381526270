<template>
  <div class="analysisForm">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      class="demo-form-inline"
      label-position="left"
      label-width="92px"
    >
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        v-show="showCompany"
        :prop="requireCompany ? 'companyIdList' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="false"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item label="选择车辆：" v-show="showCar">
        <car-tree
          ref="carSearchTree"
          :label="label"
          @getData="getVehIds"
          :companyIdList="form.companyIdList"
        ></car-tree>
      </el-form-item>
      <!-- 司机姓名： -->
      <el-form-item label="司机姓名：" v-show="showDriver">
        <el-badge
          :value="form.driverIds.length"
          :hidden="!form.driverIds.length"
          class="item"
        >
          <el-select
            v-model="form.driverIds"
            multiple
            filterable
            remote
            collapse-tags
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            :multiple-limit="50"
            :reserve-keyword="false"
          >
            <el-option
              v-for="item in driverList"
              :key="item.id"
              :label="item.driverName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-badge>
      </el-form-item>
      <!-- 用户姓名： -->
      <el-form-item label="用户姓名：" v-show="showUserName">
        <el-input
          v-model="form.userName"
          type="input"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <!-- 选择时间： -->
      <el-form-item label="选择时间：" prop="time" v-show="showMultDay">
        <el-date-picker
          cellClassName="timeRangePicker"
          v-model="form.time"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="选择日期：" prop="date" v-show="!showMultDay">
        <el-date-picker
          v-model="form.date"
          placeholder="选择日期"
          type="date"
          :picker-options="pickerDateOptions"
        ></el-date-picker>
      </el-form-item>
      <!-- 驾驶员： -->
      <el-form-item label="驾驶员：" v-show="showDriverName">
        <el-input
          v-model="form.driverName"
          type="input"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label-width="0px">
        <el-button type="primary" size="small" @click="onSearch()"
          >查询</el-button
        >
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/**
 * 报表查询form组件
 * 公司与车辆查询联动
 * 公司单选
 */
import carTree from "@/components/carTree/carSearchTree.vue";
import companySelectForSearch from "@/components/treeSelect/companySelectForSearch.vue";
import {
  getRangeDay,
  formatDate,
  formatDay,
  getStartDate,
  checkTimeLimitDay,
} from "@/common/utils/index";
import { queryDriverByName } from "@/api/lib/api";

export default {
  components: {
    carTree,
    companySelectForSearch,
  },
  props: {
    timeLimit: {
      type: Number,
      default: 31,
    },
    formatDay: {
      type: Boolean,
      default: true,
    },
    /**车辆集合需要参数（车架号/车辆id) */
    label: {
      type: String,
      default: "vehicleNo",
    },
    showCar: {
      type: Boolean,
      default: true,
    },
    showDriverName: {
      type: Boolean,
      default: false,
    },
    showMultDay: {
      type: Boolean,
      default: true,
    },
    showUserName: {
      type: Boolean,
      default: false,
    },
    showCompany: {
      type: Boolean,
      default: true,
    },
    showDriver: {
      type: Boolean,
      default: false,
    },
    //能选择今天
    chooseToday: {
      type: Boolean,
      default: false,
    },
    //公司必填
    requireCompany: {
      type: Boolean,
      default: false,
    },
    //公司不显示车队
    isCompanyShrink: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (this.chooseToday) {
        if (
          new Date() <= value[0] ||
          new Date() <= value[1]
        ) {
          callback(new Error('不能选择未来时间'))
        } else {
          callback();
        }
      } else {
        if (new Date(getStartDate()) <= value[0] || new Date(getStartDate()) <= value[1]) {
          callback(new Error("不能选择今天及未来时间"));
        } else {
          callback();
        }
      }
    };
    const validateDate1 = (rule, value, callback) => {
      if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
        callback(new Error(`只能查询${this.timeLimit}天内的数据`));
      } else {
        callback();
      }
    };
    return {
      terminalNos: [],
      driverList: [],
      loading: false,

      form: {
        time: getRangeDay(this.chooseToday),
        date: new Date().getTime() - 3600 * 1000 * 24,
        vehicleNos: [],
        driverIds: [],
        companyIdList: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay(this.chooseToday)[1] - 3600 * 1000 * 24 * 7;
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 30;
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      pickerDateOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 3600 * 1000 * 24;
        },
      },
      formRules: {
        companyIdList: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        time: [
          { required: true, message: "请选择时间范围", trigger: "blur" },
          { validator: validateDateForward, trigger: "change" },
          { validator: validateDate1, trigger: "change" },
        ],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
    };
  },

  methods: {
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate();
      this.form.companyIdList = val.length === 0 ? [] : [...val];
      //车辆选择数据清除
      this.form.vehicleNos = [];
      if (this.showCar) this.$refs.carSearchTree.resetTree();
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate();
      this.form.vehicleNos = [...val];
    },
    // 模糊搜索
    remoteMethod (query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          queryDriverByName({ driverName: query }).then((res) => {
            if (res.code === 1000) {
              this.driverList = res.data;
            }
          });
        }, 200);
      } else {
        this.driverList = [];
      }
    },

    onSearch () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {};
          if (this.showMultDay) {
            if (this.formatDay) {
              data = {
                ...this.form,
                beginTime: formatDay(this.form.time[0]),
                endTime: formatDay(this.form.time[1]),
              };
            } else {
              data = {
                ...this.form,
                beginTime: formatDate(this.form.time[0]),
                endTime: formatDate(this.form.time[1]).split(" ")[0] + " 23:59:59",
              };
            }
          } else {
            data = {
              ...this.form,
              beginTime: formatDate(this.form.date),
              endTime: formatDate(this.form.date),
            };
          }
          data.companyId =
            this.form.companyIdList.length > 0 ? this.form.companyIdList[0] : null;
          delete data.time;
          delete data.companyIdList;
          delete data.userName;
          delete data.driverName;
          delete data.driverIds;
          if (this.showUserName) data.userName = this.form.userName;
          if (this.showDriverName) data.driverName = this.form.driverName;
          if (this.showDriver) data.driverIds = this.form.driverIds;
          this.$emit("getFormVal", data);
        }
      });
    },
  },
  mounted () { },
};
</script>
